import { z } from 'zod';

import { einSchema, phoneNumberSchema, uuidSchema } from './general';
import type { FundListing, PhysicalAddress } from './models';
import {
  entityTypeSchema,
  physicalAddressSchema,
  portfolioTradeTypeSchema,
  supportedBrokerSchema,
  userIdentityInfoSchema,
} from './models';
import type { Address } from './web3';
import { addressSchema, bigIntSchema } from './web3';

export const createFundDetailsInputSchema = z.object({
  name: z.string().trim().min(1, 'Please enter a name for your new fund'),
  chainId: z.number(),
  referralSource: z
    .string()
    .trim()
    .max(99, 'Please enter a referral source less than or equal to 99 characters')
    .optional(),
  referralCode: z.string().trim().optional(),
});
export const createFundAdvisorInputSchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  email: z.string().email().trim(),
  address: physicalAddressSchema,
});
export const createFundInputSchema = z.object({
  fundInput: createFundDetailsInputSchema,
  advisor: createFundAdvisorInputSchema,
  fundSalt: z.string().nullish(),
  deploymentTransactionHash: addressSchema.nullish(),
});

export type CreateFundDetailsInput = z.infer<typeof createFundDetailsInputSchema>;
export type CreateFundAdvisorInput = z.infer<typeof createFundAdvisorInputSchema>;
export type CreateFundInput = z.infer<typeof createFundInputSchema>;

export type FundSettingsInput = Pick<FundListing, 'description' | 'name' | 'type'>;

const assembledTradeTransactionDataSchema = z.object({
  portfolioAddress: addressSchema,
  /** A USDC value on a BUY trade, A number of shares on a SELL trade */
  amount: bigIntSchema,
  callData: addressSchema,
  tradeType: portfolioTradeTypeSchema,
  chainId: z.number(),
  priceImpact: z.number().nullish(),
});
const assembledTradeEstimationsSchema = z.object({
  /** A USDC value */
  previousPositionUsdc: bigIntSchema,
  /** A USDC value */
  newEstimatedPositionUsdc: bigIntSchema,
  /** A USDC value */
  minimumNewEstimatedPositionUsdc: bigIntSchema.nullish(),
  /** A USDC value */
  estimatedGrantableBalanceChangeUsdc: bigIntSchema,
  /** A USDC value */
  minimumGrantableBalanceChangeUsdc: bigIntSchema.nullish(),
  /** A USDC value */
  estimatedNewGrantableBalanceUsdc: bigIntSchema,
  /** A USDC value */
  minimumNewGrantableBalanceUsdc: bigIntSchema.nullish(),
  /** A USDC value */
  estimatedFee: bigIntSchema,
});
export const assembledTradeResponseSchema = z.object({
  entityId: uuidSchema,
  entityType: entityTypeSchema,
  entityAddress: addressSchema,
  portfolioId: uuidSchema,
  tradeData: assembledTradeTransactionDataSchema,
  estimations: assembledTradeEstimationsSchema,
});
export type AssembledTradeResponse = z.infer<typeof assembledTradeResponseSchema>;

type ClaimantInfo = {
  claimantFirstName: string;
  claimantLastName: string;
  claimantEmail: string;
};
export type CreateWireClaimInput = ClaimantInfo & {
  wireBillingName: string;
  wireBillingAddress: PhysicalAddress;
  bankName: string;
  bankAddress: PhysicalAddress;
  bankAccountNumber: string;
  bankRoutingNumber: string;
};
export type CreateWalletClaimInput = ClaimantInfo & {
  claimantWalletAddress: Address;
  claimantAddress: PhysicalAddress;
};

export const createGrantInputSchema = z.object({
  recommender: z
    .string({ required_error: 'Recommender required' })
    .trim()
    .min(1, 'Recommender required')
    .max(250, 'Please enter a recommender less than or equal to 250 characters'),
  purpose: z
    // Purpose and special instructions are basically a single field now
    .string({ required_error: 'Please enter the grant restriction instructions' })
    .trim()
    .min(1, 'Please enter the grant restriction instructions')
    .max(500, 'Please use 500 characters or less'),
  specialInstructions: z.string().trim().optional(),
  subprojectId: uuidSchema.optional(),
  shareMyEmail: z.boolean(),
  recommendationId: uuidSchema.optional(),
});
export type CreateGrantInput = z.infer<typeof createGrantInputSchema>;

export const grantInstructionInputSchema = createGrantInputSchema.omit({
  subprojectId: true,
});
export type GrantInstructionInput = z.infer<typeof grantInstructionInputSchema>;

export const createDonationInputSchema = z
  .object({
    subprojectId: uuidSchema.optional(),
    shareMyEmail: z.boolean(),
    recommendationId: uuidSchema.optional(),
  })
  .and(
    z.union([
      z.object({ taxReceipt: z.literal(false), donorIdentity: z.undefined() }),
      z.object({ taxReceipt: z.literal(true), donorIdentity: userIdentityInfoSchema }),
    ]),
  );
export type CreateDonationInput = z.infer<typeof createDonationInputSchema>;

export const deployOrgsInputSchema = z.object({
  eins: z.array(einSchema),
  shouldSendEmails: z.boolean(),
});
export type DeployOrgsInput = z.infer<typeof deployOrgsInputSchema>;

export const brokerageInfoFormSchema = z.object({
  brokerage: supportedBrokerSchema,
  brokerageAccountNumber: z.string().min(1, { message: 'Required' }),
  brokerageContactName: z.string().min(1, { message: 'Required' }),
  brokerageEmail: z.string().email(),
  brokeragePhone: phoneNumberSchema,
});
export type BrokerageInfoFormData = z.infer<typeof brokerageInfoFormSchema>;
