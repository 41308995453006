import type { DehydratedState, FetchQueryOptions, QueryClientConfig } from '@tanstack/react-query';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import SuperJSON from 'superjson';

import { TIME_ONE_DAY_IN_SECONDS, TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';

import { isFetchError } from './index';

// Creates new instance of QueryClient - useful to repeat to avoid caching between SSG/server requests
export const newQueryClient = (additionalConfig: QueryClientConfig = {}) =>
  new QueryClient({
    ...additionalConfig,
    defaultOptions: {
      ...additionalConfig.defaultOptions,
      queries: {
        // Store in offline cache for 3 days
        gcTime: 3 * TIME_ONE_DAY_IN_SECONDS * 1000,
        // Make available for refetching after 5 minutes
        staleTime: 5 * TIME_ONE_MINUTE_IN_SECONDS * 1000,
        retry: (n, e) => {
          if (
            isFetchError(e) &&
            e.statusCode &&
            // We do not want to retry on 4xx errors
            e.statusCode >= 400 &&
            e.statusCode < 500 &&
            // The exception is 408, which is a timeout
            e.statusCode !== 408
          )
            return false;
          return n < 3;
        },
        retryDelay: n => 1000 * Math.pow(10, n),
        refetchOnWindowFocus: false,
        ...additionalConfig.defaultOptions?.queries,
      },
      mutations: {
        retry: (n, e) => {
          if (
            isFetchError(e) &&
            e.statusCode &&
            // We do not want to retry on 4xx errors
            e.statusCode >= 400 &&
            e.statusCode < 500 &&
            // The exception is 408, which is a timeout
            e.statusCode !== 408
          )
            return false;
          return n < 3;
        },
        retryDelay: n => 1000 * Math.pow(5, n),
        ...additionalConfig.defaultOptions?.mutations,
      },
    },
  });

// QueryClient instance for use only on the server-side
export const queryClientForSSR = newQueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});
// TODO: Figure out if we can still use a proxy to prevent access to the server-side query client on the client-side
// We can't do this currently because the proxy prevents access to private fields
// export const queryClientForSSR = new Proxy(
//   (function () {
//     const queryClient = newQueryClient({
//       defaultOptions: {
//         queries: {
//           retry: false,
//         },
//       },
//     });
//     // TODO: implement persister (?)
//     // const persister: Persister = {
//     //   persistClient: async (client: PersistedClient) => {
//     //   },
//     //   restoreClient: async () => {
//     //   },
//     //   removeClient: async () => {
//     //   },
//     // };
//     // persistQueryClient({
//     //   queryClient: queryClient,
//     //   persister,
//     // });
//     return queryClient;
//   })(),
//   {
//     get: (target, prop, receiver) => {
//       if (process.env.NODE_ENV === 'test') return Reflect.get(target, prop, receiver);
//       if (typeof window !== 'undefined') throw new Error('Cannot access server-side query client on client-side');
//       return Reflect.get(target, prop, receiver);
//     },
//   },
// );

export const defaultQueryClient = newQueryClient();

// Execute a series of queries, used in getStaticProps to make a dehydrated QueryClient
export const makeDehydratedQueries = async (...queries: Array<FetchQueryOptions>): Promise<string> => {
  const queryClient = newQueryClient({ defaultOptions: { queries: { retry: false } } });
  await Promise.all(
    queries.map(queryArgs => {
      try {
        return queryClient.prefetchQuery(queryArgs);
      } catch {
        throw new Error(`Error prefetching query: ${queryArgs.queryKey.toString()}`);
      }
    }),
  );

  return SuperJSON.stringify(dehydrate(queryClient));
};

export const convertDehydratedStringToState = (dehydratedState: string | undefined): DehydratedState | undefined => {
  if (!dehydratedState) return undefined;
  return SuperJSON.parse(dehydratedState);
};
