import { baseSepolia, optimismSepolia, sepolia } from 'viem/chains';

import type { ConfigEntry } from './config.types';
import { DOT_ORG_TEAM, NDAO_WALLETS } from './helpers';

export const config: ConfigEntry = {
  environmentName: 'staging',
  chains: [sepolia, optimismSepolia, baseSepolia],
  baseUrls: {
    app: 'https://app.staging.endaoment.org',
    marketing: 'https://mktg.staging.endaoment.org',
    api: 'https://api.staging.endaoment.org',
    developers: 'https://developers.staging.endaoment.org',
    docs: 'https://docs.endaoment.org',
    oauth: 'https://oauth.staging.endaoment.org',
  },
  endaoment: {
    accounts: {
      accountant: ['0x9928Fa2b51f7e2ccE76Cf0a6aED1b7e4afE0CC0f'],
      maintainer: [
        NDAO_WALLETS.PIETRO,
        NDAO_WALLETS.ZACH,
        NDAO_WALLETS.ROBBIE,
        NDAO_WALLETS.PEDRO,
        NDAO_WALLETS.RICARDO,
        NDAO_WALLETS.NOAH,
        NDAO_WALLETS.ISHAAN,
      ],
      reviewer: ['0x78062b69CffD97e6E0bBA4Cd71B0EC436B634E10'],
      keeper: [...DOT_ORG_TEAM, NDAO_WALLETS.TEST_PAUSER_WALLET],
    },
  },
  socialLoginChainId: optimismSepolia.id,
  zeroDev: {
    projectId: '146b9447-e174-4ba5-aee1-c53ee5f9a59d',
    bundlerRpcUrl: 'https://rpc.zerodev.app/api/v2/bundler/146b9447-e174-4ba5-aee1-c53ee5f9a59d',
    paymasterRpcUrl: 'https://rpc.zerodev.app/api/v2/paymaster/146b9447-e174-4ba5-aee1-c53ee5f9a59d',
  },
  circleAddress: '0xd31361f706970b0071df3ebb24fec35a4870e096',
  googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  mapboxApiKey: 'pk.eyJ1IjoiZW5kYW9tZW50IiwiYSI6ImNsaGo5MDRmdzBmZXozZnBiMHV1NWRjcGIifQ.HfvViRsi4sKeYxFYt7-BQw',
  privyAppId: 'clv1ctzeq05onquvk3ncz3n5o',
  heapAppId: '828912306',
  identifiersSecret: '0c8b1114-b048-4f6b-b33e-7ed296442555f5e1d732-6a0c-468e-9c3e-e8ce37e4e56e',
};
