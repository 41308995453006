import { z } from 'zod';

import { uuidSchema } from '../general';
import { addressSchema, bigIntSchema } from '../web3';

import { employeeStockPlanSchema, entityLabelSchema, portfolioTradeTypeSchema, stockTickerSchema } from './misc';
import { evmTokenSchema, otcTokenSchema } from './tokens';

export const baseRecommendationSchema = z.object({
  id: uuidSchema,
  collaboratorFund: z.object({
    id: uuidSchema,
    name: z.string().trim().min(1),
  }),
  collaborator: z.object({
    id: uuidSchema,
    firstName: z.string().trim().min(1),
    lastName: z.string().trim().default(''),
    email: z.string().email(),
  }),
  createdAt: z.string(),
});

const grantRecommendationSchema = baseRecommendationSchema.extend({
  type: z.literal('grant'),
  amountUsdc: bigIntSchema,
  destinationEntity: entityLabelSchema,
  destinationContractAddress: addressSchema.nullish(),
});
const grantRecommendationDetailsSchema = grantRecommendationSchema.extend({
  type: z.literal('grant_details'),
  recommender: z.string().nullish(),
  purpose: z.string(),
  specialInstructions: z.string().nullish(),
  offsetFee: z.boolean(),
  chainId: z.number(),
});

const portfolioTradeRecommendationSchema = baseRecommendationSchema.extend({
  type: z.literal('trade'),
  inputAmountUsdc: bigIntSchema,
  portfolio: z.object({
    id: uuidSchema,
    name: z.string(),
    ticker: z.string(),
  }),
  tradeType: portfolioTradeTypeSchema,
  chainId: z.number(),
});
const portfolioTradeRecommendationDetailsSchema = portfolioTradeRecommendationSchema.extend({
  type: z.literal('trade_details'),
  slippageToleranceBps: z.number().nullish(),
  isMax: z.boolean(),
});

const cryptoDonationPledgeRecommendationSchema = baseRecommendationSchema.extend({
  type: z.literal('nec_crypto_donation'),
  inputAmount: bigIntSchema,
  token: z.union([otcTokenSchema, evmTokenSchema]),
});
const cryptoDonationPledgeRecommendationDetailsSchema = cryptoDonationPledgeRecommendationSchema.extend({
  type: z.literal('crypto_donation_pledge_details'),
});

const donationRecommendationSchema = baseRecommendationSchema.extend({
  type: z.literal('donation'),
  inputAmount: bigIntSchema,
  token: evmTokenSchema,
  chainId: z.number(),
});
const donationRecommendationDetailsSchema = donationRecommendationSchema.extend({
  type: z.literal('donation_details'),
  offsetFee: z.boolean(),
});

const stockDonationPledgeRecommendationSchema = baseRecommendationSchema.extend({
  type: z.literal('stock_donation_pledge'),
  stock: stockTickerSchema,
  shares: z.number(),
});
const stockDonationPledgeRecommendationDetailsSchema = stockDonationPledgeRecommendationSchema.extend({
  type: z.literal('stock_donation_pledge_details'),
  // TODO: This is unused for now
  // donorData: z.object({})
  brokerData: z.object({
    brokerName: z.string(),
    customBrokerName: z.string().nullish(),
    brokerageAccountNumber: z.string(),
    brokerContactName: z.string(),
    brokerEmail: z.string().email(),
    brokerPhone: z.string(),
  }),
  lots: z.array(
    z.object({
      numberOfShares: z.number(),
      purchasePrice: z.number(),
      purchaseDate: z.string(),
      employeeStockPlan: employeeStockPlanSchema.nullish(),
      lotId: z.string().nullish(),
    }),
  ),
});

export const recommendationListingSchema = z.discriminatedUnion('type', [
  grantRecommendationSchema,
  portfolioTradeRecommendationSchema,
  donationRecommendationSchema,
  cryptoDonationPledgeRecommendationSchema,
  stockDonationPledgeRecommendationSchema,
]);
export type RecommendationListing = z.infer<typeof recommendationListingSchema>;
export const recommendationDetailsSchema = z.discriminatedUnion('type', [
  grantRecommendationDetailsSchema,
  portfolioTradeRecommendationDetailsSchema,
  donationRecommendationDetailsSchema,
  cryptoDonationPledgeRecommendationDetailsSchema,
  stockDonationPledgeRecommendationDetailsSchema,
]);
export type RecommendationDetails = z.infer<typeof recommendationDetailsSchema>;
